/**
 * Application-wide shared styles.
 *
 * These styles were initially inspired by Airtable's setup and were implemented
 * out of convenience as we re-implemented some of their standard controls.
 */
import { StyleSheet } from "aphrodite";
import { BACKGROUND_COLORS } from "./utils/color-system";
import { BLUE, GRAY, WHITE } from "./utils/colors";
import { Z_INDEX_POPOVER } from "./utils/layout";

/**
 * Tailwind CSS-like shared styles underlying the design system.
 *
 * TODO(charlie): Migrate this to Tailwind proper? There aren't many advantages
 * to re-implementing it here ourselves, and there are clear advantages to
 * using Tailwind (like ability to reconfigure).
 */
export const tw = StyleSheet.create({
  absolute: {
    position: "absolute",
  },
  borderBox: {
    boxSizing: "border-box",
  },
  breakWord: {
    wordWrap: "break-word",
  },
  centerAlign: {
    textAlign: "center",
  },
  clearfix: {
    ":before": {
      content: "''",
      display: "table",
    },
    ":after": {
      content: "''",
      display: "table",
      clear: "both",
    },
  },
  col12: {
    width: `${(100 * 12) / 12}%`,
  },
  col4: {
    width: `${(100 * 4) / 12}%`,
  },
  col6: {
    width: `${(100 * 6) / 12}%`,
  },
  col8: {
    width: `${(100 * 8) / 12}%`,
  },
  col10: {
    width: `${(100 * 10) / 12}%`,
  },
  cursorDefault: {
    cursor: "default",
  },
  cursorGrab: {
    cursor: "grab",
  },
  cursorPointer: {
    cursor: "pointer",
  },
  cursorText: {
    cursor: "text",
  },
  fit: {
    maxWidth: "100%",
  },
  flex: {
    display: "flex",
  },
  flexAuto: {
    flex: "1 1 auto",
    minWidth: 0,
    minHeight: 0,
  },
  flexColumn: {
    flexDirection: "column",
  },
  flexInline: {
    display: "inline-flex",
  },
  flexNone: {
    flex: "none",
  },
  flexWrap: {
    flexWrap: "wrap",
  },
  focusable: {
    ":focus": {
      boxShadow: "inset 0 0 0 2px rgba(0, 0, 0, 0.1)",
    },
  },
  heightFull: {
    height: "100%",
  },
  heightAuto: { height: "auto" },
  hidden: {
    display: "none",
  },
  inlineBlock: {
    display: "inline-block",
  },
  invisible: {
    visibility: "hidden",
  },
  itemsCenter: {
    alignItems: "center",
  },
  itemsEnd: {
    alignItems: "flex-end",
  },
  itemsStart: {
    alignItems: "flex-start",
  },
  justifyBetween: {
    justifyContent: "space-between",
  },
  justifyEvenly: {
    justifyContent: "space-evenly",
  },
  justifyCenter: {
    justifyContent: "center",
  },
  justifyEnd: {
    justifyContent: "flex-end",
  },
  justifyStart: {
    justifyContent: "flex-start",
  },
  mb1: {
    marginBottom: 8,
  },
  mb2: {
    marginBottom: 16,
  },
  mb3: {
    marginBottom: 32,
  },
  mb3AndAHalf: {
    marginBottom: 48,
  },
  mb4: {
    marginBottom: 64,
  },
  mbHalf: {
    marginBottom: 4,
  },
  minWidthFull: {
    minWidth: "100%",
  },
  ml1: {
    marginLeft: 8,
  },
  ml2: {
    marginLeft: 16,
  },
  mlHalf: {
    marginLeft: 4,
  },
  mr1: {
    marginRight: 8,
  },
  mr2: {
    marginRight: 16,
  },
  mrHalf: {
    marginRight: 4,
  },
  mrnHalf: {
    marginRight: -4,
  },
  mt1: {
    marginTop: 8,
  },
  mt2: {
    marginTop: 16,
  },
  mtHalf: {
    marginTop: 4,
  },
  mxAuto: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  m0: {
    margin: 0,
  },
  mx1: {
    marginLeft: 8,
    marginRight: 8,
  },
  mx2: {
    marginLeft: 16,
    marginRight: 16,
  },
  mxHalf: {
    marginRight: 4,
    marginLeft: 4,
  },
  mxnHalf: {
    marginRight: -4,
    marginLeft: -4,
  },
  my1: {
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
  },
  myn1: {
    marginTop: -8,
    marginBottom: -8,
  },
  noEvents: {
    pointerEvents: "none",
  },
  noWrap: {
    whiteSpace: "nowrap",
  },
  overflowAuto: {
    overflow: "auto",
  },
  overflowHidden: {
    overflow: "hidden",
  },
  p1: {
    padding: 8,
  },
  p2: {
    padding: 16,
  },
  p3: {
    padding: 32,
  },
  pHalf: {
    padding: 4,
  },
  pbHalf: {
    paddingBottom: 4,
  },
  pb1: {
    paddingBottom: 8,
  },
  pb2: {
    paddingBottom: 16,
  },
  pb3: {
    paddingBottom: 32,
  },
  pl1: {
    paddingLeft: 8,
  },
  pl3: {
    paddingLeft: 32,
  },
  pr1: {
    paddingRight: 8,
  },
  prHalf: {
    paddingRight: 4,
  },
  pt1: {
    paddingTop: 8,
  },
  pt2: {
    paddingTop: 16,
  },
  ptHalf: {
    paddingTop: 4,
  },
  px0: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  px1: {
    paddingLeft: 8,
    paddingRight: 8,
  },
  px1AndHalf: {
    paddingLeft: "0.75rem",
    paddingRight: "0.75rem",
  },
  px2: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  px3: {
    paddingLeft: 32,
    paddingRight: 32,
  },
  pxHalf: {
    paddingLeft: 4,
    paddingRight: 4,
  },
  py1: {
    paddingTop: 8,
    paddingBottom: 8,
  },
  py2: {
    paddingTop: 16,
    paddingBottom: 16,
  },
  pyHalf: {
    paddingTop: 4,
    paddingBottom: 4,
  },
  relative: {
    position: "relative",
  },
  rightAlign: {
    textAlign: "right",
  },
  rounded: {
    borderRadius: 3,
  },
  roundedBig: {
    borderRadius: 6,
  },
  selectNone: {
    userSelect: "none",
  },
  selfCenter: {
    alignSelf: "center",
  },
  selfEnd: {
    alignSelf: "flex-end",
  },
  strong: {
    // TODO(charlie): This has no effect. (It needs to be `fontWeight: "bold"`.)
    fontWeight: 500,
  },
  textDecorationNone: {
    textDecoration: "none",
  },
  textWhite: {
    color: WHITE,
    fill: WHITE,
  },
  truncate: {
    maxWidth: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  visible: {
    visibility: "visible",
  },
  white: {
    backgroundColor: WHITE,
  },
  whiteSpacePre: {
    whiteSpace: "pre",
  },
  widthFull: {
    width: "100%",
  },
});

/**
 * Shared styles that are more opinionated than the above.
 */
export const shared = StyleSheet.create({
  animate: {
    transition: ".085s all ease-in",
  },
  big: {
    fontSize: 14,
  },
  border: {
    borderStyle: "solid",
    borderWidth: 1,
  },
  borderTop: {
    borderTopStyle: "solid",
    borderTopWidth: 1,
  },
  borderLeft: {
    borderLeftStyle: "solid",
    borderLeftWidth: 1,
  },
  borderBottom: {
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
  },
  borderBottomThick: {
    borderBottomStyle: "solid",
    borderBottomWidth: 2,
  },
  borderRight: {
    borderRightStyle: "solid",
    borderRightWidth: 1,
  },
  borderDarken1: {
    borderColor: "rgba(0, 0, 0, 0.05)",
  },
  borderDarken2: {
    borderColor: "rgba(0, 0, 0, 0.1)",
  },
  borderDarken2Focus: {
    ":focus": {
      borderColor: "rgba(0, 0, 0, 0.1)",
    },
  },
  borderDarken3Hover: {
    ":hover": {
      borderColor: "rgba(0, 0, 0, 0.25)",
    },
  },
  borderThick: {
    borderStyle: "solid",
    borderWidth: 2,
  },
  borderTransparent: {
    borderColor: "transparent",
  },
  borderWhite: {
    borderColor: WHITE,
  },
  caps: {
    textTransform: "uppercase",
    letterSpacing: "0.1em",
  },
  circle: {
    borderRadius: "50%",
  },
  dark: {
    backgroundColor: "hsl(0, 0%, 20%)",
  },
  darkHover: {
    ":hover": {
      backgroundColor: "hsl(0, 0%, 20%)",
    },
  },
  darken1: {
    backgroundColor: "rgba(0, 0, 0, 0.05)",
  },
  darken1Hover: {
    ":hover": {
      backgroundColor: "rgba(0, 0, 0, 0.05)",
    },
  },
  darken2: {
    backgroundColor: "rgba(0, 0, 0, 0.1)",
  },
  darken2Hover: {
    ":hover": {
      backgroundColor: "rgba(0, 0, 0, 0.1)",
    },
  },
  darken3: {
    backgroundColor: "rgba(0, 0, 0, 0.25)",
  },
  display: {
    letterSpacing: ".01em",
    WebkitFontSmoothing: "antialiased",
    fontFeatureSettings: "'calt' 0",
  },
  focusContainer: {
    ":focus": {
      opacity: 1,
      outline: "none",
    },
  },
  focusVisible: {
    ":focus-visible": {
      outline: `2px solid ${BLUE}`,
      outlineOffset: 2,
    },
  },
  gray: {
    backgroundColor: GRAY,
  },
  huge: {
    fontSize: 32,
  },
  red: {
    backgroundColor: BACKGROUND_COLORS.redBright,
  },
  inputBase: {
    appearance: "none",
    outline: 0,
    color: BACKGROUND_COLORS.grayDark1,
    border: 0,
  },
  lightScrollbar: {
    "::-webkit-scrollbar": {
      width: 12,
      height: 12,
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
    "::-webkit-scrollbar-button": {
      display: "none",
      height: 0,
      width: 12,
    },
    "::-webkit-scrollbar-thumb": {
      backgroundColor: "hsla(0, 0%, 0%, 0.35)",
      backgroundClip: "padding-box",
      border: "3px solid rgba(0, 0, 0, 0)",
      borderRadius: 6,
      minHeight: 36,
    },
  },
  noScrollBar: {
    // Firefox
    scrollbarWidth: "none",
    "::-webkit-scrollbar": {
      // Chrome
      display: "none",
      // Safari
      background: "transparent",
      height: 0,
      width: 0,
    },
  },
  lineHeight3: {
    lineHeight: 1.25,
  },
  lineHeight4: {
    lineHeight: 1.5,
  },
  linkQuiet: {
    ":hover": {
      opacity: 0.85,
    },
  },
  linkQuieter: {
    ":hover": {
      opacity: 0.7,
    },
  },
  linkUnquiet: {
    ":hover": {
      opacity: 1,
    },
    ":focus": {
      outline: "none",
    },
  },
  linkUnquietFocusable: {
    ":hover": {
      opacity: 1,
    },
    ":focus": {
      opacity: 1,
    },
  },
  listBase: {
    margin: 0,
    padding: 0,
  },
  micro: {
    fontSize: 9,
  },
  noOutline: {
    outline: "none",
  },
  pill: {
    borderRadius: 9999,
  },
  quiet: {
    opacity: 0.75,
  },
  quieter: {
    opacity: 0.5,
  },
  quietest: {
    opacity: 0.25,
  },
  roundedBigTop: {
    borderTopRightRadius: 6,
    borderTopLeftRadius: 6,
  },
  popover: {
    zIndex: Z_INDEX_POPOVER,
  },
  small: {
    fontSize: 12,
  },
  stroked1: {
    boxShadow: "0 0 0 2px rgba(0, 0, 0, 0.1)",
  },
  textBlue: {
    color: BLUE,
    fill: BLUE,
  },
  textBlueFocus: {
    ":focus": {
      color: BLUE,
      fill: BLUE,
      outline: "none",
    },
  },
  textDarken2: {
    color: "rgba(0, 0, 0, 0.1)",
    fill: "rgba(0, 0, 0, 0.1)",
  },
  textGray: {
    color: GRAY,
    fill: GRAY,
  },
  textWhite: {
    color: WHITE,
    fill: WHITE,
  },
  unquiet: {
    opacity: 1,
  },
  // New, custom styles for Liquid Magma.
  transformed: {
    willChange: "transform",
    transformStyle: "preserve-3d",
    outline: "1px solid transparent",
  },
});
