/**
 * Reorder some items according to a known ordering of one of its properties.
 */
export function orderByPropertyValues<T, U extends string | number>(
  ordering: U[],
  property: keyof T,
  itemsToOrder: T[],
): T[] {
  const orderingIndex: { [property: string]: number } = {};
  for (let i = 0; i < ordering.length; i++) {
    orderingIndex[ordering[i]] = i;
  }
  const knownItems = itemsToOrder.filter((item) =>
    orderingIndex.hasOwnProperty(item[property] as any),
  );
  knownItems.sort(
    (a, b) =>
      orderingIndex[a[property] as any] - orderingIndex[b[property] as any],
  );
  return knownItems;
}

/**
 * Shuffle an array.
 */
export function shuffle<T>(array: T[]): T[] {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

/**
 * Returns {@code true} if the two Arrays have equal contents.
 */
export function arrayEquals<T>(
  a: Array<T> | null | undefined,
  b: Array<T> | null | undefined,
): boolean {
  if (a === b) {
    return true;
  }
  if (a == null || b == null) {
    return false;
  }
  if (a.length !== b.length) {
    return false;
  }

  return a.every((ai, i) => ai === b[i]);
}

export function sum(n: number[]): number {
  return n.reduce(
    (accumulator: number, currentValue: number) => accumulator + currentValue,
    0,
  );
}

export function range(n: number): number[] {
  return new Array(n).fill(null).map((_, i) => i);
}
