/**
 * Strut component used to add spacing to Flexbox-based layouts.
 */

const flexBasis = (size: number | string) => {
  return {
    MsFlexBasis: size,
    MsFlexPreferredSize: size,
    WebkitFlexBasis: size,
    flexBasis: size,
    flexShrink: 0,
  };
};

export default function Strut({ size }: { size: number | string }) {
  return <div style={flexBasis(size)} />;
}
