/**
 * Generic colors that aren't part of the core palette.
 */
export const WHITE = "#FFFFFF";
export const BLACK = "#000000";
export const GRAY = "#666666"; // Equivalent to HSL(0, 0%, 40%)
export const DARK_GREY = "#4D4D4D"; // Equivalent to HSL(0, 0%, 30%)
export const OFF_WHITE = "#F7F7F7";

/**
 * Colors derived from the Airtable palette.
 */
export const BLUE = "#2d7ff9";

/**
 * Convert from a hex color string to principal red, green, and blue values.
 */
function hexToRgb(hex: string): { r: number; g: number; b: number } {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (result == null) {
    throw Error(`Invalid color string: ${hex}`);
  }
  return {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  };
}

/**
 * Set the opacity of a hex-encoded color.
 *
 * @param color a hex-encoded color string (e.g., "#ffffff").
 * @param opacity a float in [0, 1] to indicate the opacity.
 * @returns {string} a RGBA-encoded string with the opacity set.
 */
export function withOpacity(color: string, opacity: number): string {
  const { r, g, b } = hexToRgb(color);
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

/**
 * Lighten a color without changing its alpha channel.
 */
export function withLighten(color: string, opacity: number): string {
  const { r, g, b } = hexToRgb(color);
  return `rgb(${opacity * r + (1 - opacity) * 255}, ${
    opacity * g + (1 - opacity) * 255
  }, ${opacity * b + (1 - opacity) * 255})`;
}
