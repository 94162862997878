// Bootstrap cutoffs.
// That is, the screen-size cutoffs used by Bootstrap (and thus implicitly
// used in our layout via our use of Bootstrap).
export const SM = 576;
export const MD = 768;
export const LG = 992;
export const XL = 1200;

// Z-indexes.
export const Z_INDEX_POPOVER = 1000;
