/**
 * Airtable-based color system. Used for generic (non-brand) color theming.
 */
import { withOpacity } from "./colors";

type Color =
  | "blue"
  | "cyan"
  | "teal"
  | "green"
  | "yellow"
  | "orange"
  | "red"
  | "pink"
  | "purple"
  | "gray";

type ColorSuffix = "" | "Dark" | "Darker" | "Medium";

export type ColorTheme = `${Color}${ColorSuffix}` | "foreignKey" | "generic";

type BackgroundColorSuffix = "Light1" | "Light2" | "Dark1" | "Bright";

type BackgroundColor =
  | `${Color}${BackgroundColorSuffix}`
  | "foreignKey"
  | "generic";

type TextColorSuffix = "dark1" | "light2";

type TextColor =
  | `text-${Color}-${TextColorSuffix}`
  | "text-white"
  | "text-foreign-key"
  | "text-generic";

export const BACKGROUND_COLORS: { [K in BackgroundColor]: string } = {
  yellowLight2: "#ffeab6",
  orangeLight2: "#fee2d5",
  redLight2: "#ffdce5",
  pinkLight2: "#ffdaf6",
  purpleLight2: "#ede2fe",
  blueLight2: "#cfdfff",
  cyanLight2: "#d0f0fd",
  tealLight2: "#c2f5e9",
  greenLight2: "#d1f7c4",
  grayLight2: "#eee",

  yellowLight1: "#ffd66e",
  orangeLight1: "#ffa981",
  redLight1: "#ff9eb7",
  pinkLight1: "#f99de2",
  purpleLight1: "#cdb0ff",
  blueLight1: "#9cc7ff",
  cyanLight1: "#77d1f3",
  tealLight1: "#72ddc3",
  greenLight1: "#93e088",
  grayLight1: "#ccc",

  yellowBright: "#fcb400",
  orangeBright: "#ff6f2c",
  redBright: "#f82b60",
  pinkBright: "#ff08c2",
  purpleBright: "#8b46ff",
  blueBright: "#2d7ff9",
  cyanBright: "#18bfff",
  tealBright: "#20d9d2",
  greenBright: "#20c933",
  grayBright: "#666",

  yellowDark1: "#b87503",
  orangeDark1: "#d74d26",
  redDark1: "#ba1e45",
  pinkDark1: "#b2158b",
  purpleDark1: "#6b1cb0",
  blueDark1: "#2750ae",
  cyanDark1: "#0b76b7",
  tealDark1: "#06a09b",
  greenDark1: "#338a17",
  grayDark1: "#444",

  foreignKey: "#e9eef9",
  generic: withOpacity("#eeeeee", 0.85),
};

export const TEXT_COLORS: { [K in TextColor]: string } = {
  "text-yellow-dark1": "#3b2501",
  "text-orange-dark1": "#6b2613",
  "text-red-dark1": "#4c0c1c",
  "text-pink-dark1": "#400832",
  "text-purple-dark1": "#280b42",
  "text-blue-dark1": "#102046",
  "text-cyan-dark1": "#04283f",
  "text-teal-dark1": "#012524",
  "text-green-dark1": "#0b1d05",
  "text-gray-dark1": "#040404",

  "text-yellow-light2": "#ffeab6",
  "text-orange-light2": "#fee2d5",
  "text-red-light2": "#ffdce5",
  "text-pink-light2": "#ffdaf6",
  "text-purple-light2": "#ede2fe",
  "text-blue-light2": "#cfdfff",
  "text-cyan-light2": "#d0f0fd",
  "text-teal-light2": "#c2f5e9",
  "text-green-light2": "#d1f7c4",
  "text-gray-light2": "#eee",

  "text-white": "#fff",

  "text-foreign-key": "#4d4d4d",
  "text-generic": withOpacity("#4d4d4d", 0.85),
};

export const TO_COLORS: { [K in ColorTheme]: [BackgroundColor, TextColor] } = {
  blue: ["blueLight2", "text-blue-dark1"],
  cyan: ["cyanLight2", "text-cyan-dark1"],
  teal: ["tealLight2", "text-teal-dark1"],
  green: ["greenLight2", "text-green-dark1"],
  yellow: ["yellowLight2", "text-yellow-dark1"],
  orange: ["orangeLight2", "text-orange-dark1"],
  red: ["redLight2", "text-red-dark1"],
  pink: ["pinkLight2", "text-pink-dark1"],
  purple: ["purpleLight2", "text-purple-dark1"],
  gray: ["grayLight2", "text-gray-dark1"],

  blueDark: ["blueLight1", "text-blue-dark1"],
  cyanDark: ["cyanLight1", "text-cyan-dark1"],
  tealDark: ["tealLight1", "text-teal-dark1"],
  greenDark: ["greenLight1", "text-green-dark1"],
  yellowDark: ["yellowLight1", "text-yellow-dark1"],
  orangeDark: ["orangeLight1", "text-orange-dark1"],
  redDark: ["redLight1", "text-red-dark1"],
  pinkDark: ["pinkLight1", "text-pink-dark1"],
  purpleDark: ["purpleLight1", "text-purple-dark1"],
  grayDark: ["grayLight1", "text-gray-dark1"],

  blueDarker: ["blueBright", "text-white"],
  cyanDarker: ["cyanBright", "text-white"],
  tealDarker: ["tealBright", "text-white"],
  greenDarker: ["greenBright", "text-white"],
  yellowDarker: ["yellowBright", "text-white"],
  orangeDarker: ["orangeBright", "text-white"],
  redDarker: ["redBright", "text-white"],
  pinkDarker: ["pinkBright", "text-white"],
  purpleDarker: ["purpleBright", "text-white"],
  grayDarker: ["grayBright", "text-white"],

  blueMedium: ["blueDark1", "text-blue-light2"],
  cyanMedium: ["cyanDark1", "text-cyan-light2"],
  tealMedium: ["tealDark1", "text-teal-light2"],
  greenMedium: ["greenDark1", "text-green-light2"],
  yellowMedium: ["yellowDark1", "text-yellow-light2"],
  orangeMedium: ["orangeDark1", "text-orange-light2"],
  redMedium: ["redDark1", "text-red-light2"],
  pinkMedium: ["pinkDark1", "text-pink-light2"],
  purpleMedium: ["purpleDark1", "text-purple-light2"],
  grayMedium: ["grayDark1", "text-gray-light2"],

  foreignKey: ["foreignKey", "text-foreign-key"],
  generic: ["generic", "text-generic"],
};
